




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.courseUser {
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  > .cell {
    text-align: left;
  }
}
.el-table th.is-right {
  > .cell {
    text-align: right;
  }
}
.myBatch {
  padding: 1rem 1rem;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
.xzht {
  /deep/ .el-form-item__content {
    display: flex;
  }
  .xzht_2 {
    margin: 0 10px;
  }
  .xzht_5 {
    color: #67C23A;
    margin-left: 10px;
  }
  .xzht_6 {
    margin-left: 10px;
    color: red;
  }
}
.xzht_jsfs {
  .xzht_jsfs_1 {
    color: red;
  }
}
.upload-workers {
  height: 30px;
  /deep/ .el-upload {
    height: 30px !important;
    width: 97px;
    border: none !important;  
    float: left;
    .el-button {
      padding: 5px 10px;
    }

  }
  /deep/ .el-upload-list {
      display: flex;
      flex-wrap: wrap;   /* 换行 */
      li {
        width: 20%;
      }
    }
}
